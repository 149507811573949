<template>
  <div>
    <el-card class="card-gutter" shadow="hover">
      <div slot="header" class="clearfix">
        <h3>协同管理</h3>
      </div>
      <div id="collaboration" class="chart"></div>
    </el-card>

    <el-card class="card-gutter" shadow="hover">
      <div slot="header" class="clearfix">
        <h3>动态</h3>
      </div>
      <ul v-for="log in logList" :key="log.action" class="log-list">
        <li class="log-list-item">
          {{ log.action }} <span class="log-time">{{ log.time }}</span>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { chartData } from "@/api/document.js";
import { UserList } from "@/api/user";
export default {
  name: "CardLeft",
  data() {
    return {


      
      logList: [
    
      ],

      limit: 10,
      page: 1,

      ChartThreeData: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "",
            type: "line",
            data: "",
          },
          {
            name: "",
            type: "line",
            data: "",
          },
          {
            name: "",
            type: "line",
            data: "",
          },
          {
            name: "",
            type: "line",
            data: "",
          },
        ],
      },
    };
  },
  mounted() {
    // this.drawUserInfo()
    this.drawCollaborationChart();
    this.getChartData();
    this.getDocument();
    

  },
  methods: {

    drawCollaborationChart() {
      const ChartThreeData = this.ChartThreeData;
      const collaborationChart = echarts.init(
        document.getElementById("collaboration")
      );
      collaborationChart.setOption(ChartThreeData);
    },
    async getChartData() {
      const ChartThreeData = this.ChartThreeData;
      let res = await chartData();
      if (res.code == 200) {
        let arr = [];
        console.log(res.data);
        res.data.forEach((item) => {
         // console.log(ChartThreeData);
          arr.push(item.name);
          ChartThreeData.series.forEach((Three, index) => {
            Three.type = res.data[index].type;
            Three.name = res.data[index].name;
            Three.data = res.data[index].countData;
          });
        });
        ChartThreeData.legend.data = arr;
        this.ChartThreeData = ChartThreeData;
        this.drawCollaborationChart();
      }
    },
    async getDocument() {
        let limit = 6
        let page  = 1
      let res = await UserList({
        limit,
        page,
      });
      if (res.code == 200) {
     //   console.log(res.data);
      
        let longinList = [];
        res.data.forEach((item) => {
            let logindata = {};
          logindata.action =item.userInfo.company+'-----'+item.userInfo.name+','+item.userLog.action
          logindata.time = item.userLog.timeCreated;
     
          longinList.push(logindata)
        })

        
      //  console.log(longinList)
        this.logList=longinList
         
      }
    },

    
  },
};
</script>

<style lang="scss" scoped>
.card-gutter {
  margin-top: 24px;
}
.log-list {
  padding-left: 14px;

  .log-list-item {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e8ecf3;
    letter-spacing: 4px;
    .log-time {
      float: right;
      font-size: 12px;
      color: #cccccc;
    }
  }
}
.chart {
  width: 800px;
  min-height: 400px;
}
</style>