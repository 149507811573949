<template>

      <el-card shadow="hover">
        <h3>工作台</h3>
        <el-row type="flex">
          <el-col :span="3">
            <el-image
              style="width: 80px; height: 80px; border-radius: 50%"
              :src="require('@/assets/img/user.png')||avatar"
              fit="cover"
            ></el-image>
          </el-col>
          <el-col :span="16" class="center">
            <div class="center-top">
              欢迎来到风电机组网络化协同设计与制造平台
            </div>
            <div class="center-bottom">
              <span>{{ username }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{userType}}</span>
              <el-divider direction="vertical"></el-divider>
              <span>自动化部</span>
            </div>
          </el-col>
          <el-col :span="6" class="right-wrapper">
            <!-- <div class="right">
              <div class="right-top">在线人数</div>
              <div class="right-bottom"> -->
                <!-- <vue-count-to :startVal="10" :endVal="36" :duration="3000"/> -->
                <!-- <span>{{userCount}}</span>
              </div>
            </div> -->
               <div class="right">
              <div class="right-top">供应商数量</div>
              <div class="right-bottom">
                <!-- <vue-count-to :startVal="10" :endVal="36" :duration="3000"/> -->
                <span>{{userTotal}}</span>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="right">
              <div class="right-top">资料数量</div>
              <div class="right-bottom">
               <span>{{materialCount}}</span>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="right">
              <div class="right-top">总记录数量</div>
              <div class="right-bottom">
               <span>{{totalNumber}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>

</template>

<script>

import { documentList } from "@/api/document";
import  {getFilist} from "@/api/user"
export default {
  name: "",
  components: {  },
  data() {
    return {
      username: "",
      userCount: "",
      documentCount: "",
      count: "",
      userType:'',
      materialCount:"",
      totalNumber:'',
      limit:10,
      page:1,
      avatar:localStorage.getItem('avatar')

    };
  },
  mounted() {
    this.getUsername();
    this.getDocument()
    this.getFileData()
    
  },
  methods: {
    getUsername() {
      this.username =  localStorage.getItem('name')
      this.userType = localStorage.getItem('userType')
      console.log(this.username)
    },
    async getDocument(){

          let res  =await  documentList()
           if(res.code==200){
            this.documentCount = res.total
            // console.log(res.total)
           }

    },
    getOnline(){
     let a  =Math.random()
     this.onlinePerson =  parseInt(a*100)
     console.log(this.onlinePerson)
    },

    async   getFileData(){
               let res  =await getFilist()
               if(res.code==200){
               this.materialCount=res.data.materialCount
               this.userCount = res.data.userCount
               this.userTotal  =res.data.userTotal
               this.totalNumber=res.data.totalNumber
               console.log(res.data)
               }
    }
  },
};
</script>

<style lang="scss" scoped>
.center {
  .center-top,
  .center-bottom {
    line-height: 2em;
  }

  .center-top {
    font-size: 18px;
  }

  .center-bottom {
    color: #bbbbbb;
    font-size: 14px;
  }
}

.right-wrapper {
  display: flex;

  .right {
    flex: 1;

    .right-top {
   
      padding-bottom: 14px;
      color: #bbbbbb;
      font-size: 14px;
      text-align: center;
    }

    .right-bottom {
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>