<template>
    <div class="index-box">
        <div class="top">
            <workbench />
        </div>
        <div class="down">
            <div class="cart_left">
                <card-left />
            </div>
            <div class="cart_right">
                <card-right />
            </div>
        </div>
    </div>
</template>

<script>
import CardLeft from '@/page/index/CardLeft';
import CardRight from '@/page/index/CardRight';
import Workbench from '@/page/index/Workbench';
export default {
    name: 'Index',
    components: { CardLeft, CardRight, Workbench }
};
</script>

<style lang="scss" scoped>
.index-box {
  overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.top{
  width: 100%;

}
.down{
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.cart_left{
  width: 60%;
  height: 700px;
}
.cart_right{
  width: 38%;
  height: 700px;
}
</style>
