<template>
  <div>
    <el-card
      class="card-gutter"
      shadow="hover"
      style="height: 875px; overflow-y: auto; overflow-x: hidden"
    >
      <div slot="header" class="clearfix">
        <h3>便捷导航/快速开始</h3>
      </div>
      <div class="tag_item">
        <el-tag
         
          style="margin-left: 10px; margin-top: 4px; cursor: pointer"
          type="info"
          closable
          size="small"
          @click="jump(link.path)"
          v-for="link in linkList"
          :key="link.path"
          >{{ link.name }}
        </el-tag>
      </div>
    </el-card>

    <!-- <el-card class="card-gutter" shadow="hover">
      <div slot="header" class="clearfix">
        <h3>协同制造</h3>
      </div>
      <div id="manufacturing" class="chart"></div>
    </el-card> -->
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "CardRight",
  data() {
    return {
      linkList: [
      
      
  
      ],
      userType:localStorage.getItem('userType')
    };
  },
  mounted() {
    // this.drawManufacturingChart();
  },
  created(){
  this. getLinkLsit()

  },
  methods: {
    jump(path) {
      this.$store.commit("SET_MAIN_INDEX", path);
      this.$router.push(`/${path}`);
    },

    getLinkLsit(){
        let   adminNavList =  [

            {
          path: "Home",
          name: "主页",
        },
        {
          path: "Data/Document",
          name: "文档查询",
        },
        {
          path: "Data/BOM",
          name: "BOM表管理",
        },
        {
          path: "Data/productInformation",
          name: "产品管理",
        },
        {
          path: "Data/productInformation",
          name: "生产资料",
        },
        {
          path: "Data/contract",
          name: "合同管理",
        },
        // {
        //   path: 'paper',
        //   name: '图纸模型传输'
        // },
        {
          path: "CollaborativeManagement/dataTransmission",
          name: "文档资料传输",
        },
        // {
        //   path: 'scheme-judgment',
        //   name: '设计方案判定'
        // },
        {
          path: "CollaborativeManagement/entrustedDesign",
          name: "委托设计",
        },
        {
          path: "CollaborativeManagement/productionScheduleManagement",
          name: "生产进度管理",
        },
        {
          path: "production-task",
          name: "生产任务管理",
        },
        // {
        //   path: 'workshop-management',
        //   name: '车间管理系统'
        // },
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求发布",
        },
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求推送",
        },
       
        // {
        //   path: 'outsourcing-manufacturing',
        //   name: '外协制造'
        // },
        // {
        //   path: 'resource',
        //   name: '资源协作'
        // },
        {
          path: "CollaborativeManagement/operationMaintenanceScheme",
          name: "运维方案",
        },
        // {
        //   path: 'data-management',
        //   name: '数据管理'
        // },
        {
          path: "CollaborativeManagement/procurementTask",
          name: "采购任务",
        },
        {
          path: "CollaborativeManagement/orderManagement",
          name: "收发货管理",
        },
        {
          path: "CollaborativeManagement/evaluationManagement",
          name: "合同评价",
        },
        // {
        //   path: 'inventory-sharing',
        //   name: '库存共享'
        // },
        {
          path: "User/userRegister",
          name: "用户新增",
        },
        {
          path: "User/userInfo",
          name: "用户信息管理",
        },
        ],  gysNavlist  =[
            {
          path: "Home",
          name: "主页",
        },
        {
          path: "Data/Document",
          name: "文档查询",
        },
        {
          path: "Data/BOM",
          name: "BOM表管理",
        },
        {
          path: "Data/productInformation",
          name: "产品管理",
        },
        {
          path: "Data/productInformation",
          name: "生产资料",
        },
   
     
        {
          path: "CollaborativeManagement/dataTransmission",
          name: "文档资料传输",
        },
    
        {
          path: "CollaborativeManagement/entrustedDesign",
          name: "委托设计",
        },
        {
          path: "CollaborativeManagement/productionScheduleManagement",
          name: "生产进度管理",
        },
        {
          path: "production-task",
          name: "生产任务管理",
        },
      
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求发布",
        },
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求推送",
        },
       
     
        {
          path: "CollaborativeManagement/operationMaintenanceScheme",
          name: "运维方案",
        },
       
        {
          path: "CollaborativeManagement/procurementTask",
          name: "采购任务",
        },
        {
          path: "CollaborativeManagement/orderManagement",
          name: "收发货管理",
        },
        ] , hzNavList = [  {
          path: "Home",
          name: "主页",
        },
        {
          path: "Data/Document",
          name: "文档查询",
        },
        {
          path: "Data/BOM",
          name: "BOM表管理",
        },
        {
          path: "Data/productInformation",
          name: "产品管理",
        },
        {
          path: "Data/productInformation",
          name: "生产资料",
        },
        {
          path: "Data/contract",
          name: "合同管理",
        },
    
        {
          path: "CollaborativeManagement/dataTransmission",
          name: "文档资料传输",
        },
   
        {
          path: "CollaborativeManagement/entrustedDesign",
          name: "委托设计",
        },
        {
          path: "CollaborativeManagement/productionScheduleManagement",
          name: "生产进度管理",
        },
        {
          path: "production-task",
          name: "生产任务管理",
        },
     
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求发布",
        },
        {
          path: "CollaborativeManagement/manufacturingRelease",
          name: "制造需求推送",
        },
       
     
        {
          path: "CollaborativeManagement/operationMaintenanceScheme",
          name: "运维方案",
        },
      
        {
          path: "CollaborativeManagement/procurementTask",
          name: "采购任务",
        },
        {
          path: "CollaborativeManagement/orderManagement",
          name: "收发货管理",
        },
        {
          path: "CollaborativeManagement/evaluationManagement",
          name: "合同评价",
        },
    ]

      if(this.userType =='海装风电') {
        this.linkList  = hzNavList
      }else   if(this.userType=='供应商'){
        this.linkList  = gysNavlist
      }else  if(this.userType=='超级管理员'){
        this.linkList = adminNavList
      }



    }

  },
};
</script>

<style lang="scss" scoped>
.card-gutter {
  margin-top: 24px;
}

.chart {
  width: 100%;
  min-height: 400px;
}
</style>